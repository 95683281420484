.ae-anno-announcement {
    &-wrapper {
        display: flex;
        align-items: center;
        width: 100%;
        background-color: var(--background-color);
        padding: 0.625rem;
        gap: 0.625rem;

        @media (min-width: 768px) {
            padding-left: 1.25rem;
            padding-right: 1.25rem;
        }
    }

    position: relative;
    animation: slide-up 0.5s ease-in-out;

    @keyframes slide-up {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    &__items {
        display: flex;
        align-items: center;
        width: 100%;
        margin-left: auto;
        margin-right: auto;

        @media (max-width: 767px) {
            flex-wrap: wrap;
            gap: 0.5rem;
        }
    }

    &__bottom {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 10;
    }

    &__controls {
        display: flex;
        align-items: center;
        gap: 0.5rem;

        button {
            all: unset;
            color: var(--text-color);
            display: flex;
            align-items: center;
            cursor: pointer;

            svg {
                height: 1rem;
                width: 1rem;
            }
        }
    }

    &__content {
        color: var(--text-color);
        -moz-column-gap: 1rem;
        column-gap: 1rem;
        display: flex;
        flex-wrap: wrap;
        font-size: var(--font-size);
        row-gap: 0.5rem;
        margin-inline-end: 25px;

        @media (max-width: 767px) {
            flex-direction: column;
        }
    }

    &__text {
        color: var(--text-color);
        font-size: var(--font-size);
        margin: 0;

        a {
            color: var(--text-color);
            font-size: var(--font-size);
            text-decoration-line: underline;
        }
    }

    &__dismissed {
        display: none;
    }

    &__button {
        color: var(--text-color);
        font-size: var(--font-size);
    }

    &__dismiss-button {
        all: unset;
        color: var(--text-color);
        inset-inline-end: 1.5rem;
        position: absolute;

        &-label {
            clip: rect(0, 0, 0, 0);
            border-width: 0;
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            white-space: nowrap;
            width: 1px;
        }

        &-icon {
            height: 1.25rem;
            width: 1.25rem;
        }
    }
}

body[dir="rtl"] {
    .ae-anno-announcement {
        &__next-button {
            transform: rotateY(180deg);
        }
        &__previous-button {
            transform: rotateY(180deg);
        }
    }
}

@media only screen and (max-width: 667px) {
    .ae-anno-announcement {
        &__content {
            margin-inline-end: unset;
        }

        &__dismiss-button {
            inset-block-start: 0.2em;
            inset-inline-end: 1em;
        }
    }
}
